import {imagePath} from '../environment'
import {graphql, Link, StaticQuery} from 'gatsby'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import React from 'react'
import Image from "./image";

const Author = ({author, lang, type}) => {
  let name = lang === "en" ? author.name_en : author.name
  let profile= lang === "en" ? author.profile_en : author.profile
  let printProfile = (<p className="author__profile">{profile}</p>)
  let position = lang === "en" ? author.position_en : author.position
  let postfix = ""
  if (type === "simple") {
    printProfile = ""
    postfix = "--simple"
  }
  return (
      <section className={`author`}>
        <div className={`author__photo${postfix}`} >
          {(image => {
            return image == null ? (<Image/>) : (
                <Img fixed={image.childImageSharp.fixed}/>)
          })(author.photo)}
        </div>
        <div className={`author__detail${postfix}`}>
          <h3 className={`author__name${postfix}`}>{name}</h3>
          <p className={`author__position${postfix}`}>{position}</p>
          {printProfile}
        </div>
      </section>
  )
}

export default Author