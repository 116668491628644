import React from 'react'
import ReactDOM from 'react-dom'
import ReactMarkDown from 'react-markdown'
import {Link, graphql} from 'gatsby'
import Layout from '../components/layout'
import Author from '../components/author'

import fb from '../images/icon_facebook.svg'
import tw from '../images/icon_twitter.svg'
import Image from "../components/image";
import Img from "gatsby-image";
import ArticleList from "../components/article-list";
import CodeBlock from "../components/codeblock"

import {deployPath, getExerpt} from "../environment"
import SEO from "../components/seo";


const BlogTemplate = ({data}) => (
    <Layout lang="ja" pagePath={data.strapiBlog.slug} category={data.strapiBlog.category.slug}>
      <SEO title={data.strapiBlog.title} keywords={[`Pikon log`, `Pikon Inc.`, `技術ブログ`]} description={getExerpt(data.strapiBlog.content)} lang="ja"/>
      <article className="article">
        <header className="article__article-header">
          <time className="article__article-date">{data.strapiBlog.publishDate}</time>
          <Link to={`/category/${data.strapiBlog.category.slug}`} className="article__article-category">{data.strapiBlog.category.name}</Link>
          <h1 className="article__article-title">{data.strapiBlog.title}</h1>
          {((author)=>{
            if(author) {
              return (
                  <aside className="article__author--simple author">
                    <Author author={author} lang="ja" type="simple"/>
                  </aside>
              )
            }
          })(data.strapiAuthor)}
        </header>

        <section className="article__article-featured-image">
          {(image => {
            return image == null ? "" : (
                <Img fluid={image.childImageSharp.fluid}/>)
          })(data.strapiBlog.featuredImage)}
        </section>

        <section className="article__article-body">
          <ReactMarkDown
              linkTarget="_blank"
              source={data.strapiBlog.content}
              renderers={{ code: CodeBlock }}
          />
        </section>

        <footer className="article__article-footer">
          <div className="article__article-link-box">
            <ul className="article__tags tags">
              {data.strapiBlog.tags.map(document => (
                  <li className="tags__tag-item" key={document.id}>
                    <Link to={`/tag/${document.slug}`}>{document.name}</Link>
                  </li>
              ))}
            </ul>
            <ul className="article__article-share share">
              <li className="share__share-item">
                <a href={`http://twitter.com/share?text=${data.strapiBlog.title}&amp;url=${deployPath + "/" + data.strapiBlog.slug}`} rel="nofollow" target="_blank">
                  <img src={tw} alt="" width="28" height="28"/>
                </a>
              </li>
              <li className="share__share-item">
                <a href={`http://www.facebook.com/share.php?u=${deployPath + "/" + data.strapiBlog.slug}`} target="_blank">
                  <img src={fb} alt="" width="28" height="28"/>
                </a>
              </li>
            </ul>
          </div>
          {((author)=>{
            if(author) {
              return (
                  <aside className="article__author author">
                    <Author author={author} lang="ja"/>
                  </aside>
              )
            }
          })(data.strapiAuthor)}
        </footer>
      </article>

      {(()=>{
        if(data.allStrapiBlog.edges.length !== 0){
          return (<ArticleList blogs={data.allStrapiBlog.edges} type="enumeration" lang="ja" heading="関連記事"/>)
        }
      })()}

    </Layout>
)

export default BlogTemplate

export const query = graphql`
  query BlogTemplate($id: String!, $authorId: String!, $categorySlug: String!) {
    strapiBlog(id:{eq:$id}){
      featuredImage {
        id
        childImageSharp {
          id
          fluid(maxWidth: 1000) {
            base64
            tracedSVG
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
            originalImg
            originalName
            presentationWidth
            presentationHeight
          }
        }
      }
      category{
        name
        name_en
        slug
      }
      tags{
        id
        name
        name_en
        slug
      }
      title
      title_en
      slug
      content
      content_en
      publishDate(formatString: "YYYY.MM.DD")
    }
    strapiAuthor(strapiId:{eq:$authorId}){
      id
      name
      name_en
      profile
      profile_en
      position
      position_en
      photo {
        id
        childImageSharp{
          fixed(width: 70) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    allStrapiBlog(
      filter:{
        category:{
          slug:{
            eq:$categorySlug
          }
        },
        status:{
          eq:true
        },
        id:{
          ne:$id
        }
      },
      sort:{
        fields: [publishDate, id]
  	    order: [DESC, DESC]
      }
    ){
      edges{
        node{
          id
          title
          publishDate(formatString: "YYYY.MM.DD")
          strapiId
          slug
          isTranslated
          category {
            id
            name
            slug
          }
          featuredImage {
            id
            childImageSharp{
              fixed(width: 160,height: 120) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`